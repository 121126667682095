import HeaderUniversal from '../../components/universalComponents/headerUniversal/HeaderUniversal';
import cl from './BotSettings.module.css'
import InputUniversal from '../../components/universalComponents/inputUniversal/InputUniversal';
import firstTwoPageStore from '../../store/FirstTwoPageStore';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { HeadlineBody } from '../../textStyles/TextStyleComponents';
import { observer } from 'mobx-react-lite';
import ModalUniversal from '../../components/universalComponents/modalUniversal/ModalUniversal';

const BotSettings = observer(() => {
    const tg = window.Telegram.WebApp;
    const navigate = useNavigate();

    const botUsername = localStorage.getItem('botUsername');

    const [showValidationModal, setShowValidationModal] = useState(false);
    const [validationModalText, setValidationModalText] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);

    useEffect(() => {
        if (!firstTwoPageStore.hasFetchedSelfEmployedData) {
            navigate('*')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cleanUsername = (username) => {
        if (username.startsWith('@')) {
            return username.slice(1); // Удаляет первый символ, если это '@'
        }
        return username;
    };

    useEffect(() => {
        const getSelfEmployedRequest = async () => {
            try {
                const response = await axios.get(`https://test-client-bot.gros.pro/client_bots_api/bots/${botUsername}`);

                firstTwoPageStore.setToken(response.data.token)
                firstTwoPageStore.setUsername(response.data.username)

                console.log("Загружены данные о юзернейме и токене", response);
            } catch (error) {
                if (error.response) {
                    // Сервер вернул ответ с кодом ошибки, который находится в пределах диапазона 2xx
                    const errorData = error.response.data;
                    console.error("Ошибка:", errorData.error);
                    console.error("Описание:", errorData.description);
                } else if (error.request) {
                    // Запрос был сделан, но ответа не было получено
                    console.error("Запрос был сделан, но ответа не было получено", error.request);
                } else {
                    // Произошла другая ошибка при настройке запроса
                    console.error("Ошибка:", error.message);
                }
            }
        }

        getSelfEmployedRequest();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        tg.MainButton.setText("Сохранить изменения");
        tg.MainButton.color = "#34C759";
        tg.MainButton.textColor = "#FFFFFF";

        const handleMainButtonOnClick = async () => {
            tg.MainButton.disable();
            const patchData = {
                botUsername: cleanUsername(firstTwoPageStore.username.trim()),
                botApiToken: firstTwoPageStore.token.trim()
            }

            if (patchData.botUsername < 5) {
                setValidationModalText('Юзернейм не может быть меньше 5 символов');
                setShowValidationModal(true);
                return;
            }
            if (patchData.botUsername > 32) {
                setValidationModalText('Юзернейм не может быть больше 32 символов');
                setShowValidationModal(true);
                return;
            }
            if (!patchData.botUsername.toLowerCase().endsWith('bot')) {
                setValidationModalText('Юзернейм должен заканчиваться на "bot"');
                setShowValidationModal(true);
                return;
            }

            try {
                const response = await axios.patch(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}`, patchData);
                console.log("Токен и юзернейм успешно обновлен", response.data);
                navigate("*");
            } catch (error) {
                setShowErrorModal(true);
                tg.MainButton.enable();
                if (error.response) {
                    // Сервер вернул ответ с кодом ошибки, который находится в пределах диапазона 2xx
                    const errorData = error.response.data;
                    console.error("Ошибка:", errorData.error);
                    console.error("Описание:", errorData.description);
                } else if (error.request) {
                    // Запрос был сделан, но ответа не было получено
                    console.error("Запрос был сделан, но ответа не было получено", error.request);
                } else {
                    // Произошла другая ошибка при настройке запроса
                    console.error("Ошибка:", error.message);
                }
            }
        }



        const handleBackButtonOnClick = () => {
            navigate('*');
        }

        tg.MainButton.onClick(handleMainButtonOnClick);
        tg.BackButton.onClick(handleBackButtonOnClick);

        return () => {
            tg.MainButton.offClick(handleMainButtonOnClick);
            tg.BackButton.offClick(handleBackButtonOnClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const isTokenMasked = firstTwoPageStore.token.includes('******');
        const isUsernameFilled = firstTwoPageStore.username.trim() !== '';
        const isTokenFilled = firstTwoPageStore.token.trim() !== '';

        if (isUsernameFilled && isTokenFilled && !isTokenMasked) {
            if (!tg.MainButton.isVisible) tg.MainButton.show();
        } else {
            if (tg.MainButton.isVisible) tg.MainButton.hide();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstTwoPageStore.token, firstTwoPageStore.username]);


    return (
        <div>
            <HeaderUniversal>Параметры бота</HeaderUniversal>

            <HeadlineBody className={cl.text}>Эти данные должны быть актуальны всегда, не меняйте их просто так. От этого зависит работа системы в вашем боте.</HeadlineBody>

            <HeadlineBody className={cl.text}>
                Если вы хотите поменять бота, введите его данные в поля ниже. Старый бот перестанет работать.
            </HeadlineBody>


            <InputUniversal
                title={"Юзернейм"}
                value={firstTwoPageStore.username}
                placeholder={"Заканчивается на bot"}
                setValue={firstTwoPageStore.setUsername}
            />

            <InputUniversal
                title={"Токен"}
                value={firstTwoPageStore.token}
                placeholder={"Много букв и цифр"}
                setValue={firstTwoPageStore.setToken}
            />

            <HeadlineBody>
                После редактирования данных изменения вступят в силу в течение минуты. В это время бот не будет работать.
            </HeadlineBody>

            <HeadlineBody className={cl.instruction}>
                <a href={"https://telegra.ph/Instrukciya-po-sozdaniyu-bota-08-11"} className={cl.link}>
                    Инструкция по созданию бота
                </a>
            </HeadlineBody>

            <HeadlineBody>
                <a href={"https://t.me/GrosServiceSupport"} className={cl.link}>
                    Поддержка
                </a>
            </HeadlineBody>

            {showValidationModal &&
                <ModalUniversal
                    text={validationModalText}
                    setIsVisible={setShowValidationModal}
                    cancelText={'Окей'}
                />
            }

            {showErrorModal &&
                <ModalUniversal
                    text={'Не удалось изменить параметры бота. Попробуйте снова'}
                    setIsVisible={setShowErrorModal}
                    cancelText={'Окей'}
                />
            }
        </div>
    );
});

export default BotSettings