export const initialNumbersValue = (heightValue = 54, numbersLength = 24, value = null) => {

   const arrayOfSelectedValue = [
      // {
      //    number: '00',
      //    translatedValue: (heightValue * 2).toString(),
      //    selected: false,
      // },
      // {
      //    number: numbersLength === 4 ? '15' : '01',
      //    translatedValue: heightValue.toString(),
      //    selected: false,
      // },
   ];

   let count = 0;

   // for (let index = 0; index < 1; index++) {
      for (let j = 0; j < numbersLength; j++) {
         // if (j < 2) { // may be I should delete second condition
         //    continue;
         // }

         let translatedValue;

         if (j === 0) {
            translatedValue = (heightValue * 2).toString();
         } else if (j === 1) {
            translatedValue = heightValue.toString();
         } else {
            translatedValue = `-${count}`;
         }

         if (j === value) {
            if (j.toString().length === 1) {
               arrayOfSelectedValue.push({
                  number: `0${j.toString()}`,
                  translatedValue,
                  selected: true,
               });
            } else {
               arrayOfSelectedValue.push({
                  number: j.toString(),
                  translatedValue,
                  selected: true,
               });
            }
            count += heightValue;
            continue;
         }
         if (j.toString().length === 1) {
            arrayOfSelectedValue.push({
               number: `0${j.toString()}`,
               translatedValue,
               selected: false,
            });
         } else {
            arrayOfSelectedValue.push({
               number: j.toString(),
               translatedValue,
               selected: false,
            });
         }

         if (j >= 2) count += heightValue;
      }
   // }

   return arrayOfSelectedValue;
};

export const returnSelectedValue = (heightValue = 54, numbersLength = 24) => {
   const arrayOfSelectedValue = [
      // {
      //    number: '00',
      //    translatedValue: (heightValue * 2).toString(),
      //    arrayNumber: 0,
      // },
      // {
      //    number: numbersLength === 4 ? '15' : '01',
      //    translatedValue: heightValue.toString(),
      //    arrayNumber: 1,
      // },
   ];
   let count = 0;
   
   // for (let index = 0; index < 1; index++) {
      for (let j = 0; j < numbersLength; j++) {
         // if (j < 2) {
         //    continue;
         // }

         let translatedValue;

         if (j === 0) {
            translatedValue = (heightValue * 2).toString();
         } else if (j === 1) {
            translatedValue = heightValue.toString();
         } else {
            translatedValue = `-${count}`;
         }

         if (j.toString().length === 1) {
            arrayOfSelectedValue.push({
               number: `0${j.toString()}`,
               translatedValue,
               selected: false,
            });
         } else {
            arrayOfSelectedValue.push({
               number: j.toString(),
               translatedValue,
               selected: false,
            });
         }

         if (j >= 2) count += heightValue;
      }
   // }
   return arrayOfSelectedValue;
};
