import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import templatesStore from "../../../../store/TemplatesStore";
import cl from "./SingleTemplatePage.module.css";
import HeaderUniversal from "../../../universalComponents/headerUniversal/HeaderUniversal"
import GapForTemplate from "./gapForTemplate/GapForTemplate";
import { ReactComponent as DeleteTemplateIcon } from "../../../../icons/DeleteTemplate.svg";
import getStore from "../../../../store/GetStore";
import ModalUniversal from "../../../universalComponents/modalUniversal/ModalUniversal";
import validationStore from "../../../../store/ValidationStore";
import axios from "axios";
import { observer } from "mobx-react-lite";
import _ from 'lodash';
import firstTwoPageStore from "../../../../store/FirstTwoPageStore";
// import { TimePicker } from 'react-ios-time-picker';


const SingleTemplatePage = observer(() => {
    const { id } = useParams();
    const navigate = useNavigate();
    const tg = window.Telegram.WebApp;

    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showValidationModal, setShowValidationModal] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModalText, setErrorModalText] = useState('');

    useEffect(() => {
        if (!firstTwoPageStore.hasFetchedSelfEmployedData) {
            navigate('*');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const currentTemplate = templatesStore.templatesData.find((template) => template.id.toString() === id);

    // if (id !== 'new' && !currentTemplate) { // Чтоб при перезагрузки страницы не выдавало template undefined
    //     return null;
    // }

    const [template, setTemplate] = useState(id !== 'new' ? currentTemplate : {
        id: 'temp',
        name: '',
        gaps: [
            {
                start: '09:00',
                end: '17:00'
            }
        ],
    }); // Локальное состояние для хранения текущего шаблона
    const [copyOfTemplateName] = useState(template.name)

    const [originalTemplate] = useState(_.cloneDeep(template));

    const botUsername = localStorage.getItem('botUsername')

    const patchRequest = async () => {
        tg.MainButton.disable();

        const updatedInitialTimeData = getStore.initialTimeData.map(day => {
            if (day.templateId === parseInt(id, 10)) {
                return { ...day, templateId: 0 }; // Изменяем templateId на 0
            }
            return day;
        });

        const patchData = {
            workingDays: {
                version: getStore.version,  // Версия данных
                days: updatedInitialTimeData // Передаем обновленные данные дней
            },
            templates: templatesStore.templatesData
        }

        try {
            const response = await axios.patch(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}/schedule`, patchData);
            console.log("Патч запрос шаблонов выполнен", response.data);
            navigate("/scheduleTemplates");
        } catch (error) {
            setShowErrorModal(true);
            tg.MainButton.enable();
            if (error.response) {
                // Сервер вернул ответ с кодом ошибки, который находится в пределах диапазона 2xx
                const errorData = error.response.data;
                console.error("Ошибка:", errorData.error);
                console.error("Описание:", errorData.description);
            } else if (error.request) {
                // Запрос был сделан, но ответа не было получено
                console.error("Запрос был сделан, но ответа не было получено", error.request);
            } else {
                // Произошла другая ошибка при настройке запроса
                console.error("Ошибка:", error.message);
            }
        }
    }

    const deleteTemplate = async () => {
        // Удаляем шаблон
        templatesStore.deleteTemplateById(id);

        // Обновляем дни, которые использовали этот шаблон
        getStore.timeData.forEach(date => {
            if (date.templateId === parseInt(id, 10)) {
                date.templateId = 0;
                getStore.updateOrAddDay(date);
            }
        });

        // const updatedInitialTimeData = getStore.initialTimeData.map(day => {
        //     if (day.templateId === parseInt(id, 10)) {
        //         return { ...day, templateId: 0 }; // Изменяем templateId на 0
        //     }
        //     return day;
        // });

        // // Формируем данные для PATCH запроса
        // const patchData = {
        //     workingDays: {
        //         version: getStore.version,  // Версия данных
        //         days: updatedInitialTimeData, // Передаем обновленные данные дней
        //     }
        // };

        // try {
        //     const botUsername = localStorage.getItem('botUsername');
        //     const response = await axios.patch(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}/schedule`, patchData);
        //     console.log("Патч запрос выполнен для обновления всех дней с измененным шаблоном", patchData);
        //     console.log(response.data);
        // } catch (error) {
        //     console.error("Ошибка выполнения PATCH запроса для обновления дней", error);
        // }

        setErrorModalText('Не удалось удалить данный шаблон. Попробуйте снова');
        patchRequest();
    };


    const areGapsValid = (gaps) => {
        const sortedGaps = [...gaps].sort((a, b) => a.start.localeCompare(b.start));

        for (let i = 0; i < sortedGaps.length; i++) {
            // Проверяем, что время начала строго меньше времени окончания для каждого промежутка
            if (sortedGaps[i].start >= sortedGaps[i].end) {
                return false; // Некорректный промежуток
            }

            // Если это не первый промежуток, проверяем, чтобы не было пересечений
            if (i > 0 && sortedGaps[i].start < sortedGaps[i - 1].end) {
                return false; // Найдено пересечение
            }
        }
        return true; // Все промежутки корректны и не пересекаются
    };


    // Функция для сортировки промежутков по времени
    const sortGapsByTime = (gaps) => {
        return gaps.sort((a, b) => a.start.localeCompare(b.start));
    };


    const saveTemplate = async () => {
        if (id === 'new') {
            if (!areGapsValid(template.gaps)) {
                setShowValidationModal(true);
                return;
            }

            if (template.name && areGapsValid(template.gaps)) {
                templatesStore.addTemplate(template);

                setErrorModalText('Не удалось добавить новый шаблон. Попробуйте снова');
                patchRequest();
            }
        } else {
            if (!areGapsValid(template.gaps)) {
                setShowValidationModal(true);
                return;
            }
            const sortedGaps = sortGapsByTime(template.gaps);
            const updatedTemplate = { ...template, gaps: sortedGaps };
            templatesStore.updateTemplateById(id, updatedTemplate);
            getStore.timeData.forEach(day => {
                if (day.templateId === parseInt(id, 10)) {
                    day.templateId = 0;
                    getStore.updateOrAddDay(day);
                }
            });

            // const updatedInitialTimeData = getStore.initialTimeData.map(day => {
            //     if (day.templateId === parseInt(id, 10)) {
            //         return { ...day, templateId: 0 }; // Изменяем templateId на 0
            //     }
            //     return day;
            // });

            // // Формируем данные для PATCH запроса
            // const patchData = {
            //     workingDays: {
            //         version: getStore.version,  // Версия данных
            //         days: updatedInitialTimeData, // Передаем обновленные данные дней
            //     }
            // };

            // try {
            //     const botUsername = localStorage.getItem('botUsername');
            //     const response = await axios.patch(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}/schedule`, patchData);
            //     console.log("Патч запрос выполнен для обновления всех дней с измененным шаблоном", patchData);
            //     console.log(response.data);
            // } catch (error) {
            //     console.error("Ошибка выполнения PATCH запроса для обновления дней", error);
            // }

            setErrorModalText('Не удалось изменить данный шаблон. Попробуйте снова');
            patchRequest();

        }
    };

    useEffect(() => {

        const handleBackButtonOnClick = () => {
            navigate("/scheduleTemplates");
        }

        const handleMainButtonOnClick = () => {
            if (id === 'new') {
                saveTemplate();
            } else if (!_.isEqual(template.gaps, originalTemplate.gaps)) {
                const today = new Date().toISOString().split('T')[0];
                const daysUsingTemplate = getStore.timeData.filter(day => day.templateId === parseInt(id, 10) && day.date >= today);
                if (daysUsingTemplate.length > 0) {
                    setShowSaveModal(true);
                } else {
                    saveTemplate();
                }
            } else if (!_.isEqual(template.name, originalTemplate.name)) {
                templatesStore.updateTemplateById(id, { ...template, name: template.name });
                setErrorModalText('Не удалось изменить данный шаблон. Попробуйте снова');
                patchRequest();
            } else {
                navigate('/scheduleTemplates');
            }
        };

        tg.BackButton.onClick(handleBackButtonOnClick);

        tg.MainButton.onClick(handleMainButtonOnClick);

        return () => {
            tg.BackButton.offClick(handleBackButtonOnClick);
            tg.MainButton.offClick(handleMainButtonOnClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template]);

    useEffect(() => {
        if (template.name) {
            if (!tg.MainButton.isActive) {
                tg.MainButton.enable()
            }
            if (tg.MainButton.color !== tg.themeParams.button_color) {
                tg.MainButton.color = tg.themeParams.button_color
                tg.MainButton.textColor = tg.themeParams.button_text_color
            }
        } else {
            tg.MainButton.disable();
            if (tg.colorScheme === "light") {
                tg.MainButton.color = "#e8e8e9";
                tg.MainButton.textColor = "#b9b9ba";
            } else {
                tg.MainButton.color = "#2f2f2f";
                tg.MainButton.textColor = "#606060";
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template.name])

    if (id !== 'new' && !currentTemplate) { // Чтоб при перезагрузки страницы не выдавало template undefined
        return null;
    }



    const onChangeGap = (gapIndex, field, value) => {
        setTemplate((prevTemplate) => {
            const newGaps = prevTemplate.gaps.map((gap, index) => {
                if (index === gapIndex) {
                    return { ...gap, [field]: value };
                }
                return gap;
            });
            return { ...prevTemplate, gaps: newGaps };
        });
    };


    const onChange = (event) => {
        const value = event.target.value;

        if (value.length > validationStore.maxRegularInputLength) {
            setTemplate((prevTemplate) => ({
                ...prevTemplate,
                name: value.slice(0, validationStore.maxRegularInputLength)
            }));
        } else {
            setTemplate((prevTemplate) => ({
                ...prevTemplate,
                name: value
            }));
        }
    };

    const onDeleteGap = (gapIndex) => {
        setTemplate((prevTemplate) => {
            const newGaps = prevTemplate.gaps.filter((_, index) => index !== gapIndex);
            return { ...prevTemplate, gaps: newGaps };
        });
    };

    const onAddGap = () => {
        // Получаем последний промежуток, если он существует
        const lastGap = template.gaps[template.gaps.length - 1];

        // Вычисляем новый промежуток с увеличенным временем на час
        const newStart = lastGap ? addHour(lastGap.end) : "09:00"; // Начало нового промежутка
        const newEnd = addHour(newStart); // Окончание нового промежутка

        // Создаем новый промежуток
        const newGap = { start: newStart, end: newEnd };

        // Обновляем состояние template, добавляя новый промежуток
        setTemplate((prevTemplate) => ({
            ...prevTemplate,
            gaps: [...prevTemplate.gaps, newGap],
        }));
    };

    // Функция для добавления часа к времени
    const addHour = (time) => {
        const [hours, minutes] = time.split(":");
        let newHours = parseInt(hours, 10) + 1;
        if (newHours < 10) {
            newHours = "0" + newHours;
        } else if (newHours >= 24) {
            newHours = "00";
        }
        return `${newHours}:${minutes}`;
    };

    const handleDeleteButtonClick = () => {
        const today = new Date().toISOString().split('T')[0];
        const daysUsingTemplate = getStore.timeData.filter(day => day.templateId === parseInt(id, 10) && day.date >= today);
        if (daysUsingTemplate.length > 0) {
            setShowDeleteModal(true);
        } else {
            deleteTemplate();
        }
    }


    // const onConfirmSave = () => {
    //     if (!areGapsValid(template.gaps)) {
    //         alert("Промежутки времени не должны пересекаться.");
    //         setShowSaveModal(false);
    //         return;
    //     }
    //     const sortedGaps = sortGapsByTime(template.gaps);
    //     const updatedTemplate = { ...template, gaps: sortedGaps };
    //     templatesStore.updateTemplateById(id, updatedTemplate);

    //     getStore.currentTimeData.forEach(day => {
    //         if (day.templateId === parseInt(id, 10)) {
    //             day.templateId = null;
    //             getStore.updateOrAddDay(day);
    //         }
    //     });

    //     navigate("/scheduleTemplates");
    // };



    return (
        <div>
            <HeaderUniversal>{id === 'new' ? "Новый шаблон" : copyOfTemplateName}</HeaderUniversal>

            

            <div className={cl.containerWithoutHeader}>
                <input
                    className={cl.inpt}
                    placeholder={"Название шаблона"}
                    value={template.name}
                    onChange={onChange}
                />

                <div className={cl.gapContainer}>
                    {template.gaps?.map((gap, index) => (
                        <GapForTemplate
                            key={index}
                            gap={gap}
                            gapIndex={index}
                            onChangeGap={onChangeGap}
                            showDeleteButton={template.gaps.length > 1}
                            onDeleteGap={onDeleteGap}
                            isLastGap={index === template.gaps.length - 1}
                        />
                    ))}
                </div>

                {template.gaps.length <= 2 && (
                    <button className={`${cl.btn} ${cl.addBtn}`} onClick={onAddGap}>
                        Добавить промежуток
                    </button>
                )}

                {id !== "1" && (
                    <button className={`${cl.btn} ${cl.deleteBtn}`} onClick={handleDeleteButtonClick}>
                        <DeleteTemplateIcon className={cl.deleteIcon} />
                        Удалить шаблон
                    </button>
                )}
            </div>
            {showSaveModal &&
                <ModalUniversal
                    text={'Для тех дней, которые использовали этот шаблон, рабочее время не изменится, они просто перестанут использовать этот шаблон'}
                    setIsVisible={setShowSaveModal}
                    onConfirm={saveTemplate}
                    confirmText={'Сохранить'}
                    cancelText={'Назад'}
                />
            }

            {showDeleteModal &&
                <ModalUniversal
                    text={'Для тех дней, которые использовали этот шаблон, рабочее время не изменится, они просто перестанут использовать этот шаблон'}
                    setIsVisible={setShowDeleteModal}
                    onConfirm={deleteTemplate}
                    confirmText={'Удалить'}
                    cancelText={'Назад'}
                />
            }

            {showValidationModal &&
                <ModalUniversal
                    text={'Промежутки времени не должны пересекаться'}
                    setIsVisible={setShowValidationModal}
                    // onConfirm={deleteTemplate}
                    // confirmText={'Удалить'}
                    cancelText={'Окей'}
                />
            }

            {showErrorModal &&
                <ModalUniversal
                    text={errorModalText}
                    setIsVisible={setShowErrorModal}
                    cancelText={'Окей'}
                />
            }
        </div>
    );
});

export default SingleTemplatePage;